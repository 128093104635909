<template>
  <div class="row">
    <div class="col-md-3">
      <form v-bind:action="forceupdateroute" method="post">
        <input type="hidden" name="_token" v-bind:value="token">
          <input type="hidden" name="survey_nr" v-bind:value="survey_nr">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="endlink">Endlinks <span class="required">*</span></label>
            <select name="endlink" id="endlink" v-model="my_endlink" class="form-control">
              <option value="all" selected>Alle endlinks</option>
              <option v-bind:value="key" v-bind:key="key" v-for="(value, key) in this.endlinks">
                {{ value }}
              </option>
            </select>
          </div>
        </div>
        <span class="font-italic">Huidige servertijd: <b>{{this.servertime}}</b> </span>
        <div class="form-group row">
          <div class="col-md-8">
            <label for="start_date">Start datum</label>
            <input value=""
                   type="date" name="start_date" class="form-control"
                   v-model="start"
                   id="start_date">
          </div>
          <div class="col-md-4">
            <label for="">Tijd</label>
            <input id="start_time" type="text"
                   v-model="startTime"
                   class="time ui-timepicker-input form-control"
                   autocomplete="off">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-8">
            <label for="end_date">Eind datum</label>
            <input value=""
                   type="date" name="end_date" class="form-control"
                   v-model="end"
                   id="end_date">
          </div>
          <div class="col-md-4">
            <label for="">Tijd</label>
            <input id="end_time" type="text"
                   v-model="endTime"
                   class="time ui-timepicker-input form-control"
                   autocomplete="off">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <button @click="check" name="check" value="bekijk" type="button" class="btn btn-primary col-md-12">Bekijk</button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <input style="width: 100%;" type="submit" value="Exporteer CSV" name="export_csv" class="btn btn-primary">
          </div>
          <div class="col-md-6">
            <input style="width: 100%;" type="submit" value="Forceer update" name="force_update"
                   class="btn btn-primary">
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-12">
          <div class="spinner-border text-info" role="status" v-if="loader">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-show="data != null">
            <table class="table" style="width: 90%">
              <thead>
              <tr>
                <th>Endlink</th>
                <th>Completes</th>
                <th>Screenouts</th>
                <th>Quota Fulls</th>
                <th>Quality Check</th>
                <th>Dropout</th>
                <th>IP block</th>
                <th>Laatste complete</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, index) in getDataset">
                <td>{{ value.title }}</td>
                <td>{{ value.complete }}</td>
                <td>{{ value.screenout }}</td>
                <td>{{ value.full }}</td>
                <td>{{ value.ql }}</td>
                <td>{{ value.dor }}%</td>
                <td>{{ value.ip_block }}</td>
                <td><span v-if="value.title != 'totaal'">{{ value.last_complete }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="height: 300px;">
          <div class="chart-container" style="height:300px; width:800px">
            <canvas id="shareChart"></canvas>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "monitordata",
  props: ['survey_nr', 'endlinks', 'servertime', 'monitor_data_route', 'forceupdateroute'],
  data() {
      return {
        start: null,
        my_endlink: "all",
        end: null,
        data: null,
        token: null,
        startTime: null,
        endTime: null,
        loader: false
      }
  },
  created() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  },
  computed: {
    getDataset() {
      return this.data;
    }
  },
  mounted() {
    let data = this;
    $('#start_time').timepicker({
      timeFormat: 'H:i',
      minTime: '00:00:00',
      startTime: new Date(0, 0, 0, 30, 0, 0),
      interval: 30,
      defaultTime: "00:00"
    }).on('changeTime', function () {
      data.startTime = $("#start_time").val();
    });
    $('#end_time').timepicker({
      timeFormat: 'H:i',
      minTime: '00:00:00',
      startTime: new Date(0, 0, 0, 30, 0, 0),
      interval: 30,
      defaultTime: "00:00"
    }).on('changeTime', function () {
      data.endTime = $("#end_time").val();
    });
  },
  methods: {
    check() {

      this.loader = true
      axios.get(this.monitor_data_route, {
        params: {
          survey_nr: this.survey_nr,
          country: this.country === "all" ? null : this.country,
          endlinks: this.my_endlink === "all" ? null : this.my_endlink,
          start_date: this.start,
          end_date: this.end,
          start_time: this.startTime,
          end_time: this.endTime,

        }
      }).then(response => {
        this.chart(response.data.chart);
        this.data = response.data.dataset;
        this.loader = false;
      })
    },
    chart(_data) {

      let object = {
        "labels" : _data.labels,
        "datasets": _data.dataset
      };

      if (this.data !== null) {
        chart.refresh(object, _data.labels);
      } else {
        chart.setup("shareChart", "bar", object, _data.labels);
      }
    }
  }
}
</script>

<style scoped>

</style>