$(document).ready(function ()  {
    $(".clickable:not(td > .no-click)").on('click', function (event) {
        console.log($(event.target).parent());
        if ($(event.currentTarget).hasClass('no-click') || $(event.target).hasClass('no-click') || $(event.target).parent().hasClass('no-click')) {
            return
        }
        let url = $(this).attr('data-url');
        if (url !== null && url !== undefined) {
            window.location.href = url;
        }
    });
});